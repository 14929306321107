<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <base-material-card color="drawerColorSecondary" ref="form">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Capturar Datos</div>
          </template>
          <v-text-field v-model="nombre" counter="20" label="Nombre"></v-text-field>
          <v-select v-model="unidad" :items="this.unidades" label="Unidades"></v-select>
          <v-checkbox v-model="interno" :label="`Interno`"></v-checkbox>
          <v-checkbox v-model="descripcion" :label="`Descripción`"></v-checkbox>
          <v-btn small color="verdeBoton" @click="limpiarCampos()">Limpiar Campos</v-btn>
          <v-btn small color="verdeBoton" @click="abrirModal();" v-text="$t('guardarCambios')" />
        </base-material-card>
      </v-col>

      <v-col cols="12" md="8">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-light" v-text="$t('material')" />
          </template>
          <v-spacer></v-spacer>
          <v-col cols="4" class="text-right">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line></v-text-field>
          </v-col>

          <v-responsive class="overflow-y-auto" max-height="calc(90vh - 350px)">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="materiales"
              :search="search"
              :items-per-page="15"
              item-key="idMaterial"
              single-select
              dense
              class="elevation-2"
              no-data-text="No se Encuentran Materiales"
            >
              <!-- <template v-slot:body=" { items } ">
                <tbody >
                  
                <v-responsive class="overflow-y-auto" max-height="calc(90vh - 350px)" max-width="100%">
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{item}}</td>
                    <td class="text-xs-right">{{item.nombre}}</td>
                    <td class="text-xs-right">{{item.unidadM}}</td>
                    <td class="text-xs-right">
                      <v-simple-checkbox v-model="item.IPI" disabled></v-simple-checkbox>
                    </td>
                    <td class="text-xs-right">
                      <v-simple-checkbox v-model="item.descripcion" disabled></v-simple-checkbox>
                    </td>
                    <td>
                      <v-icon medium color="primary" @click="deleteItem(item)">mdi-delete</v-icon>
                    </td>
                  </tr>
                </v-responsive>
                </tbody>
              </template>-->
              <template v-slot:item.IPI="{ item }">
                <v-simple-checkbox v-model="item.IPI" disabled></v-simple-checkbox>
              </template>
              <template v-slot:item.descripcion="{ item }">
                <v-simple-checkbox v-model="item.descripcion" disabled></v-simple-checkbox>
              </template>
              <template v-slot:item.accion="{ item }">
                <v-icon medium color="primary" @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-responsive>
        </base-material-card>

        <v-col cols="12" class="text-right">
          <v-dialog v-model="dialog" persistent max-width="340">
            <v-card>
              <v-card-title class="headline">¿Dar de alta el material?</v-card-title>
              <v-card-text class="text-center">
                Al dar aceptar se harán los cambios en el
                servidor.
                <br />Nombre:
                <b>{{this.nombre.toUpperCase()}}</b> Unidad:
                <b>{{this.unidad.toUpperCase()}}</b>
                <br />Interno:
                <b>{{this.interno == true ? 'Si' : ' No'}}</b> Descripción:
                <b>{{this.descripcion == true ? 'Si' : ' No'}}</b>
              </v-card-text>
              <v-card-actions class="text-center">
                <v-spacer></v-spacer>
                <v-btn color="verdeBoton darken-1" text @click="dialog = false">Cancelar</v-btn>
                <v-btn color="verdeBoton darken-1" text @click="agregarMaterial()">{{$t('aceptar')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

        <!--
        <v-col cols="12" class="text-right">
          <v-dialog v-model="dialogCambios" persistent max-width="340">
            <v-card>
              <v-card-title class="headline">¿Guardar Cambios?</v-card-title>
              <v-card-text>
                Al dar aceptar se harán los cambios en el
                servidor.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogCambios = false">Cancelar</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                      agregarMaterial();
                      limpiarCampos();
                    "
                >{{$t('aceptar')}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EditarCategorias",
  data() {
    return {
      sizeScreen: 0,
      dialog: false,
      dialogCambios: false,
      editing: false,
      onSelect: false,
      selected: [],
      titulo: "Registrar Material",
      nombre: "",
      unidad: "Pieza",
      rules: {
        required: (value) => !!value || "Obligatorio.",
        counter: (value) => value.length <= 20 || "Máximo 15 caracteres.",
      },
      interno: false,
      descripcion: false,
      search: "",
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Unidades", value: "unidadM" },
        { text: "Interno", value: "IPI" },
        { text: "Descripción", value: "descripcion" },
        { text: "Acciones", value: "accion", sortable: false, width: "8%" },
      ],
      materiales: [],
      val: "",
      dialog: false,
      unidades: [
        "Pieza",
        "Faradios",
        "Amperes",
        "Ohms",
        "Voltios",
        "Vatios",
        "Herzios",
        "Coulombios",
        "Henrios",
      ],
    };
  },
  mounted() {
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((materiales) => {
        this.materiales = materiales;
      });
  },
  methods: {
    abrirModal() {
      let index = this.materiales.findIndex(
        (obj) => obj.nombre == this.nombre.toUpperCase()
      );

      if (index != -1) {
        this.$alert(
          "Ya existe el mismo material en el sitema",
          "No es posible agrega ese material",
          "warning"
        ).then(() => this.limpiarCampos());
        return;
      }

      if (this.nombre === "") {
        this.$alert(
          "No es posible agrega ese material",
          "Ingrese un nombre",
          "warning"
        ).then();
        return;
      }

      this.dialog = !this.dialog;
    },
    limpiarCampos() {
      this.nombre = "";
      this.unidad = "";
      this.interno = false;
      this.descripcion = false;
      this.editing = false;
      this.onSelect = false;
    },
    agregarMaterial() {
      fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
        body: JSON.stringify({
          nombre: this.nombre.toUpperCase(),
          unidadM: this.unidad.toUpperCase(),
          descripcion: this.descripcion,
          IPI: this.interno,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.dialog = !this.dialog;
          this.materiales.push(data);
        });
    },
    deleteItem(item) {
      fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/materiales", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.$store.state.cuenta.token,
        },
        body: JSON.stringify({
          idMaterial: item.idMaterial,
        }),
      }).then((response) => {
        let posicion = this.materiales.indexOf(item);
        this.materiales.splice(posicion, 1);
      });
      this.onSelect = false;
    },
    editItem(item) {
      this.editing = !this.editing;
      this.unidad = item.unidad;
      this.nombre = item.nombre;
      this.interno = item.interno;
      this.descripcion = item.descripcion;
    },
    guardarCambios() {
      this.selected.forEach((material) => {
        fetch(
          process.env.VUE_APP_URL_BACKEND + "/administrador/materiales",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.$store.state.cuenta.token,
            },
            body: JSON.stringify({
              idMaterial: material.idMaterial,
              nombre: this.nombre,
              unidadM: this.unidad,
              descripcion: this.descripcion,
              IPI: this.interno,
            }),
          }
        ).then(() => {
          let posicion = this.materiales.indexOf(material);
          this.materiales.splice(posicion, 1);
          this.materiales.push(material);
        });
      });
      this.selected = [];
      this.dialogCambios = !this.dialogCambios;
      this.onSelect = false;
    },
  },
};
</script>